<template>
  <div class="vld-parent cyb-login base-page">
    <login-form :hasForm="false" :top-bar-action="topBarAction" pageclass="darker">
      <template #text-content>
        <div class="p-d-flex p-flex-column p-ai-start p-jc-center page-width p-d-flex-1">
          <div class="content">
            <div class="base-template">
                <h1><span>{{ $t('emailconfirmation.title') }}</span></h1>
                <div v-if="!processed">
                  <p>{{ $t('emailconfirmation.message') }}</p><br />
                  <progress-spinner style="width:50px;height:50px" strokeWidth="2"/>
                </div>
                <div v-else>
                  <div v-if="verified">
                    <p>
                      {{ $t('emailconfirmation.successMessage.line1') }}<br />
                      {{ $t('emailconfirmation.successMessage.line2') }}<br />
                      {{ $t('emailconfirmation.successMessage.line3') }}<br />
                      {{ $t('emailconfirmation.successMessage.line4') }}
                    </p>
                  </div>
                  <div v-else>
                    <p>{{ $t('emailconfirmation.errorMessage.line1') }}</p>
                  </div>
                </div>
                <br />
                <Button v-if="processed"
                  :label="$t('emailconfirmation.goToLogin')"
                  class="p-button p-button-sm p-pl-6 p-pr-6" @click="this.$router.push('/login')">
                </Button>
              </div>
          </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import { authService } from '../services/auth.service';
import LoginForm from '../components/LoginForm.vue';

export default {
  components: {
    LoginForm
  },
  data() {
    return {
      message: '',
      verified: false,
      processed: false,
    };
  },
  computed: {
    topBarAction() {
      return this.processed ? "login" : null;
    }
  },
  mounted() {
    this.confirmEmail();
  },
  methods: {
    confirmEmail() {
      authService
        .confirmEmail(this.$route.params.usertoken)
        .then(() => {
          this.verified = true;
          this.processed = true;
        })
        .catch(() => {
          this.verified = false;
          this.processed = true;
        });
    }
  },
};
</script>